import axios from 'axios';

const api = axios.create({
    baseURL: 'https://review.mtu.edu.vn/backend/api',
    // baseURL: 'http://localhost:5100/backend/api',
});

export const registerUser = (data) => api.post('/auth/register', data);
export const loginUser = (data) => api.post('/auth/login', data);
export const uploadExam = (data) => api.post('/exams', data);
export const assignTeacher = (examId, data) => api.put(`/admin/exams/${examId}/assign`, data);
export const updateUserRole = (userId, data) => api.put(`/admin/users/${userId}/role`, data);
export const getUsers = () => api.get('/admin/users');
export const getExams = () => api.get('/admin/exams');
export const getTeacherExams = (teacherId) => api.get(`/exams/teacher/${teacherId}`);
export const getStudentExams = (studentId) => api.get(`/exams/student/${studentId}`);
export const uploadFeedback = (examId, data) => api.post(`/exams/feedback/${examId}`, data);
export const uploadRevision = (examId, data) => api.post(`/exams/revision/${examId}`, data);
export const updateExamStatus = (examId, statusData) => api.patch(`/admin/exams/${examId}/status`, statusData);
export const deleteExam = (examId) => api.delete(`/exams/${examId}`);
export const deleteUser = (userId) => api.delete(`/admin/users/${userId}`);
export const uploadAdminRevision = (examId, data) => api.post(`/exams/adminFile/${examId}`, data);