// src/pages/Login.js
import React, { useState } from 'react';
import { loginUser } from '../services/api';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

const Login = () => {
    const [formData, setFormData] = useState({ username: '', password: '' });
    const navigate = useNavigate();
    const { login } = useUser();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await loginUser(formData);
            login(response.data);
            // Redirect based on role
            if (response.data.role === 'admin') {
                navigate('/admin-dashboard');
            } else if (response.data.role === 'teacher') {
                navigate('/teacher-exams');
            } else if (response.data.role === 'student') {
                navigate('/student-exams');
            }
        } catch (error) {
            alert('Error logging in');
        }
    };

    return (
        <Form className="formGeneral" onSubmit={handleSubmit}>
            <Form.Group controlId="username">
                <Form.Label>Tài Khoản</Form.Label>
                <Form.Control type="text" name="username" placeholder="Enter username" value={formData.username} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="password">
                <Form.Label>Mật Khẩu</Form.Label>
                <Form.Control type="password" name="password" placeholder="Enter password" value={formData.password} onChange={handleChange} required />
            </Form.Group>
            <Button variant="primary" type="submit">
                Đăng Nhập
            </Button>
        </Form>
    );
};

export default Login;
