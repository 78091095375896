import React from 'react';
const Homepage = () => {
    return (
      <div className="container">
        <main className="col-xs-12 col-sm-10 col-md-12">
          <div id="main-content" className="page_index_journal row">
            <div className="col-md-8 about_journal top_left_content_block">
	            <h3>GIỚI THIỆU VỀ TẠP CHÍ</h3>
	
	            <p><b>Tạp chí Khoa học và Công nghệ Trường Đại học Xây dựng Miền Tây</b> (Journal of Science, Technology and Engineering Mien Tay Construction University), viết tắt là (JSTE-MTU) là ấn phẩm khoa học có phản biện độc lập, truy cập mở, áp dụng tiêu chuẩn trong nước và quốc tế.</p>
	
	            <p>Tạp chí hoạt động theo Giấy phép số 159/GP-BTTTT, ngày 29 tháng 03 năm 2022 của Bộ Thông tin và Truyền thông, và được cấp mã chuẩn quốc tế ISSN: 2525-2615.</p>
	
	            <p>Tôn chỉ và mục đích của Tạp chí:</p>
	            <ol>
		            <li>Thông tin về các hoạt động của Trường Đại học Xây dựng Miền Tây.</li>
		            <li>Định kỳ theo kỳ hạn xuất bản để thông tin chuyên sâu, chuyên ngành; đăng tải kết quả nghiên cứu mới và ứng dụng trong các lĩnh vực liên quan đến các ngành đào tạo của Trường.</li>
	            </ol>
	            <p>Tạp chí đăng tải các công trình khoa học có chất lượng bằng tiếng Việt và tiếng Anh, với kỳ hạn xuất bản: 01 kỳ/03 tháng, (04 kỳ/năm), thời gian phát hành các tháng 3, 6, 9, và 12.</p>
	
	            <p>Tạp chí công bố các bài nghiên cứu gốc (sau quá trình phản biện kín) trong nhiều lĩnh vực chuyên sâu, chuyên ngành của tạp chí bao gồm (không giới hạn) đến các lĩnh vực sau:</p>
	            <ol>
		            <li>Kỹ thuật Xây dựng;</li>
		            <li>Kiến trúc, Quy hoạch, Quản lý;</li>
		            <li>Giao thông, Hạ tầng, Môi trường, Tài nguyên nước, Cấp thoát nước;</li>
		            <li>Kỹ thuật xây dựng công trình giao thông, Kỹ thuật cơ sở hạ tầng, Kỹ thuật môi trường, Kỹ thuật cấp thoát nước;</li>
                <li>Kế toán;</li>
                <li>Công nghệ thông tin;</li>
                <li>Và các lĩnh vực khác như: Kinh tế bền vững, Kinh tế xây dựng, Phân tích tài chính doanh nghiệp, Phân tích hiệu quả kinh tế,…</li>
	            </ol>
	            <p>Tạp chí Khoa học và Công nghệ Trường Đại học Xây dựng Miền Tây rất hân hạnh được nhận đăng các công trình mới của các nhà khoa học trong và ngoài nước.</p>
	
	            <p>Tạp chí xin cảm ơn sự tin cậy và hợp tác của tập thể phản biện và quý độc giả đã đồng hành suốt thời gian qua.</p>

            </div>
            <div className="col-md-4 top_right_content_block">
              <div className="content-block">
                <img className="media-object" alt="" src="https://review.mtu.edu.vn/tapchi.jpg" />
                <ul>
                  <li>
                    <a href="https://vjol.info.vn/index.php/mtu/issue/archive">Số xuất bản</a>
                  </li>
                  <li>
                    <a href="https://vjol.info.vn/index.php/mtu/issue/view/8092">Số hiện tại</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
};

export default Homepage;
