import React from 'react';
import { ChatDotsFill } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';

const ZaloChat = ({ phoneNumber }) => {
    const handleChat = () => {
        window.open(`https://zalo.me/${phoneNumber}`, '_blank');
    };

    return (
        <Button variant="primary" onClick={handleChat} style={{ position: 'fixed', bottom: '20px', right: '20px', borderRadius: '50%', width: '60px', height: '60px' }}>
            <ChatDotsFill size={30} />
        </Button>
    );
};

export default ZaloChat;
